import React, {useContext, useCallback, useState, useEffect} from 'react';
import {Formik} from 'formik';
import {gql, useMutation} from '@apollo/client';
import {some, reduce} from 'lodash';
import {useRouter} from 'next/router';

import LoginUI from '../src/components/auth/login/LoginUI';
import UserContext from '../src/context/user';
import util from '../src/util/auth';
import authUtil from '../src/util/auth';

const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

function Login(props) {
  const {isLoggedIn, paymentStatus, login}: any = useContext(UserContext);
  const router = useRouter();

  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [hasFormErrors, setHasFormErrors] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      const promoCode = sessionStorage.getItem('promo_code');
      const promoType = sessionStorage.getItem('promo_type');
      const params = [];
      if (promoCode) {
        params.push(`code=${promoCode}`);
      }
      if (promoType) {
        params.push(`type=${promoType}`);
      }
      if (params.length) {
        router.push(`/checkout?${params.join('&')}`);
        return;
      }

      router.push('/account');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, paymentStatus]);

  const [loginMutation, {loading}] = useMutation(LOGIN, {
    onCompleted: data => {
      login(data.login);

      const promoCode = sessionStorage.getItem('promo_code');
      const promoType = sessionStorage.getItem('promo_type');
      const params = [];
      if (promoCode) {
        params.push(`code=${promoCode}`);
      }
      if (promoType) {
        params.push(`type=${promoType}`);
      }
      if (params.length) {
        router.push(`/checkout?${params.join('&')}`);
        return;
      }

      router.push('/account');
    },
    onError: error => {
      //if username doesn't exist:
      const {graphQLErrors = []} = error || {};

      if (
        some(
          graphQLErrors,
          ({extensions}) => extensions && extensions.code === 'BAD_USER_INPUT',
        )
      ) {
        setSnackbarMessage('Incorrect email or password. Please try again');
        setHasFormErrors(true);
      } else if (
        some(graphQLErrors, ({message}) => message === 'Reset password')
      ) {
        setSnackbarMessage(
          'Please finish resetting password. A link has been set to your email.',
        );
      }
    },
  });

  const validate = useCallback(values => {
    const errors = {};
    util.loginFields.forEach(({name}) => {
      if (!values[name]) errors[name] = 'Required';
    });

    return errors;
  }, []);

  const handleSubmit = useCallback(
    values => {
      loginMutation({
        variables: {
          password: values.password,
          email: values.email,
        },
      });
    },
    [loginMutation],
  );

  //   Handle nextjs redirect??
  //   if (context.isLoggedIn === true) {
  //     return <Redirect to='/' />;
  //   }

  return (
    <Formik
      component={formikBag => (
        <LoginUI
          {...formikBag}
          {...props}
          loading={loading}
          snackbarMessage={snackbarMessage}
          resetSnackbarMessage={() => setSnackbarMessage('')}
          hasFormErrors={hasFormErrors}
        />
      )}
      initialValues={reduce(
        util.loginFields,
        (acc, {name}) => {
          acc[name] = '';
          return acc;
        },
        {},
      )}
      onSubmit={handleSubmit}
      validate={validate}
    />
  );
}

export default Login;
